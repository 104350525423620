import {useEffect} from 'react'

import {
    publish,
    subscribe,
    subscribeOnce,
    unsubscribe,
} from '@/script/event.mjs'

const bc = new BroadcastChannel('eventbus')

bc.onmessage = e => {
    const {eventType} = e.data ?? {}

    if (eventType) {
        publish(sharedEventBus, eventType, e.data)
    }
}

export const sharedEventBus = {
    async publish(eventType, data) {
        const m = {data, eventType}
        publish(this, eventType, m)
        bc.postMessage(m)
    },

    subscribe(...args) {subscribe(this, ...args)},
    subscribeOnce(...args) {subscribeOnce(this, ...args)},
    unsubscribe(...args) {unsubscribe(this, ...args)},
}

export const useSharedEventBus = (eventType, callback) => {
    useEffect(
        () => {
            sharedEventBus.subscribe(eventType, callback)
            return () => sharedEventBus.unsubscribe(eventType, callback)
        },

        [eventType, callback]
    )
}
